export const percentInViewport = (element, widthPercent = 100, heightPercent = 100, parent) => {
	if (!element || element.style.display == 'none' || element.offsetParent === null) return false;
	const elementRect = element.getBoundingClientRect();
	let parentRect = null;
	if (parent) {
		parentRect = parent.getBoundingClientRect();
	}
	const parentLeft = parentRect?.left || 0;
	const parentRight = parentRect?.right || window.innerWidth || document.documentElement.clientWidth;

	const width = elementRect.width;
	const height = elementRect.height;
	const visibleWidth = (width * widthPercent) / 100;
	const visibleHeight = (height * heightPercent) / 100;
	return elementRect.top >= -1 * (height - visibleHeight) && elementRect.left >= parentLeft - (width - visibleWidth) && elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + (height - visibleHeight) && elementRect.right <= parentRight + (width - visibleWidth);
};
