/* eslint-disable no-new */

import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';
import { Slider } from './Class/Slider';
import { SWHREF } from './Class/SWHREF';
import { SVGPosition } from './Class/SVGPosition';
import { NoBubble } from './Class/NoBubble';

const SLIDER_CONTAINER_SELECTOR = '.module-container > .device-card--slider';
const DEVICE_CARD_CONTAINER_SELECTOR = '.module-container.device-card';
const TITLE_SELECTOR = '.device-card-item__title';

const DEVICE_CARD_SLIDER_SELECTORS = {
	SLIDER_WRAPPER_SELECTOR: '.device-card__content',
	ENABLED_CLASSNAME: 'device-card--slider-enabled',
	NEXT_BUTTON_SELECTOR: '.device-card-controls-next',
	PREV_BUTTON_SELECTOR: '.device-card-controls-prev'
};

const SVG_POSITION_OPTIONS = {
	initDelay: 1000
};

const DEVICE_CARD_SLIDER_PARAMS = {
	freeMode: true,
	loop: false,
	loopedSlides: 0,
	scrollbar: '.device-card-scrollbar',
	scrollbarHide: false,
	scrollbarDraggable: true,
	mousewheelControl: true,
	slideClass: 'device-card-item',
	centeredSlides: false,
	grabCursor: true,
	slidesPerView: 'auto',
	spaceBetween: 0
};

$(document).ready(function () {
	$(SLIDER_CONTAINER_SELECTOR).each(function () {
		new Slider(this, Object.assign({}, DEVICE_CARD_SLIDER_PARAMS), DEVICE_CARD_SLIDER_SELECTORS);
		SWHREF.init(this);
		NoBubble.init(this);
		new SVGPosition(this);
	});

	$(DEVICE_CARD_CONTAINER_SELECTOR).each(function () {
		new BoxHeights({ container: this, boxSelector: TITLE_SELECTOR });
		SWHREF.init(this);
		NoBubble.init(this);
		new SVGPosition(this, SVG_POSITION_OPTIONS);
	});
});
