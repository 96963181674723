/* eslint-disable camelcase */

import $ from 'jquery';
import Utils from '../../../../common/utils';
const COOKIE_RETRIES = 60;
function getCookieValue(cookieName) {
	var name = cookieName + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var decodedCookies = decodedCookie.split(';');
	for (var i = 0; i < decodedCookies.length; i++) {
		var cookie = decodedCookies[i];
		while (cookie.charAt(0) == ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name) == 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}
}
export default class PersonalizedModulesApi {
	constructor() {
		this.timestamp = new Date().getTime();
	}
	getSessionUUID() {
		return Utils.getContextId();
	}

	getHeaders() {
		return {
			'x-correlation-id': Utils.generateUuid(),
			'x-request-id': Utils.generateUuid()
		};
	}
	waitForPersistentCookie() {
		return new Promise((resolve, reject) => {
			const getPortalPersistentCookie = (retry = 0) => {
				if (retry > COOKIE_RETRIES) reject();
				const portalPersistentCookie = getCookieValue(Utils.PORTAL_PERSISTENT_COOKIE_NAME);
				if (portalPersistentCookie) {
					resolve();
					return;
				}

				setTimeout(() => {
					getPortalPersistentCookie(++retry);
				}, 1000);
			};
			getPortalPersistentCookie();
		});
	}

	/**
	 * Több modul is használhatja ezt a restet.
	 * @param cetModuleId: ez dönti el, hogy milyen típusú adatstruktúra jön a responseba.
	 */
	async postModuleInfo(m28moduleConfig) {
		try {
			await this.waitForPersistentCookie();
			let requestData = {
				pageUrl: window.location.href,
				productNames: m28moduleConfig.productNames
			};

			if (m28moduleConfig.cetModuleId === 'M28') {
				requestData.category = m28moduleConfig.category;
			}
			if (m28moduleConfig.refurbished) {
				requestData.refurbished = m28moduleConfig.refurbished;
			}
			return $.ajax({
				type: 'POST',
				url: m28moduleConfig.personalizedOfferUrl,
				contentType: 'application/json',
				headers: this.getHeaders(),
				dataType: 'json',
				data: JSON.stringify(requestData)
			});
		} catch (err) {
			throw new Error();
		}
	}
}
