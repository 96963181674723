import $ from 'jquery';
const TMP_CLASS = 'tmp--hover';
export class ButtonHoverHandler {
	/**
	 *
	 * @param element jQuery selector which trigger this effect
	 * @param parent class name of the parent element
	 */
	static init(element, parentClass) {
		$(element).hover(
			function () {
				let parent = $(this).parents('.' + parentClass);
				parent.removeClass(parentClass).addClass(TMP_CLASS).attr('data-tmp-swhref', parent.attr('data-swhref')).removeAttr('data-swhref').attr('data-tmp-swtarget', parent.attr('data-swtarget')).removeAttr('data-swtarget');
			},
			function () {
				let parent = $(this).parents('.' + TMP_CLASS);
				parent.addClass(parentClass).removeClass(TMP_CLASS).attr('data-swhref', parent.attr('data-tmp-swhref')).removeAttr('data-tmp-swhref').attr('data-swtarget', parent.attr('data-tmp-swtarget')).removeAttr('data-tmp-swtarget');
			}
		);
	}
}
