import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';
import { SWHREF } from './Class/SWHREF';

const INLINE_PROMO_CUSTOM_MAGENTA1__ILLUSTRATION_CONTAINER_SELECTOR = '.module-container .inline-promo-custom-magenta1__container';

const SVG_POSITION_OPTIONS = {
	initDelay: 500
};

$(INLINE_PROMO_CUSTOM_MAGENTA1__ILLUSTRATION_CONTAINER_SELECTOR).each(function () {
	// eslint-disable-next-line no-new
	new SVGPosition(this, SVG_POSITION_OPTIONS);
	SWHREF.init(this);
});
