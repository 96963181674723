import $ from 'jquery';

/**
 * A html-ben a viewport-os meta tag content-jében lehet turkálni a:
 * - setProperty hívással (ha üres értéket adunk meg akkor kitörli az adott propertyt mint pl $(...).css)
 * - properties-nek értékadással.
 */
export class ViewportAPI {
	constructor() {
		this._$viewport = $('meta[name="viewport"]');
	}

	get properties() {
		let contents = this._$viewport.attr('content').split(',');
		let trimmed = contents.map(Function.prototype.call, String.prototype.trim);
		let props = {};

		trimmed.forEach(function (property) {
			let keyVal = property.split('=');
			props[keyVal[0]] = keyVal[1];
		});

		return props;
	}

	set properties(properties) {
		let contents = [];

		for (let [key, value] of Object.entries(properties)) {
			contents.push(`${key}=${value}`);
		}

		this._$viewport.attr('content', contents.join(', '));
	}

	setProperty(property, value) {
		let properties = this.properties;

		if (value && value.length) {
			properties[property] = value;
		} else {
			delete properties[property];
		}

		this.properties = properties;
	}

	getProperty(property) {
		return this.properties[property];
	}
}
