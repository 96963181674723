import $ from 'jquery';
import PersonalizedModulesApi from '../api/PersonalizedModulesApi';
import Utils from '../../../../common/utils';
import EventLog from './EventLog';

/**
 * perszonalizált M17 modul
 */
export default class HorizontalRedirector {
	constructor() {
		this.service = new PersonalizedModulesApi();
		this.DOM_SELECTORS = {
			CONTAINER_SELECTOR: 'data-horizontal-redirector-module-id',
			BOX_ID_SELECTOR: 'data-m17p-box-id',
			DEFAULT_BOX: '[data-m17p-box-id="default"]',
			PERSONALIZED_OFFER_URL: 'data-m17p-personalized-url',
			MODULE_ID: 'data-m17p-module-id',
			LOADER: '.m17p-inline-promo-loader',
			LINK: '.js-nobubble'
		};
		this.DEFAULT_BOX_ID = 'default';

		this.getPersonalizedData();
	}

	static getAttributeValue(attribute) {
		return $(`[${attribute}]`).val($(attribute)).attr(attribute);
	}

	getPersonalizedData() {
		let personalizedOfferUrl = HorizontalRedirector.getAttributeValue(this.DOM_SELECTORS.PERSONALIZED_OFFER_URL);
		let cetModuleId = HorizontalRedirector.getAttributeValue(this.DOM_SELECTORS.MODULE_ID);

		this.service.postModuleInfo(personalizedOfferUrl, cetModuleId).then((response) => {
			this.initPersonalizedOffer(response);
		});
	}

	/**
	 * url: doboz id, amit shiwaban beszerkesztenek
	 * offerId: valójában az ahová navigálni kell
	 * BE oldalon így tudták megoldani, hogy felvan cserélve logikailag a két property értéke.
	 * @param data
	 */
	initPersonalizedOffer(data) {
		if (data && data.url) {
			let $selectedHorizontalRedirector = $(`[${this.DOM_SELECTORS.BOX_ID_SELECTOR}="${data.url}"]`)[0];

			if (data.offerId && $selectedHorizontalRedirector && Utils.isLoggedIn()) {
				$($selectedHorizontalRedirector).find(this.DOM_SELECTORS.LINK)[0].href = data.offerId;
				$($selectedHorizontalRedirector).show();
				this.initEventLog($selectedHorizontalRedirector);
			} else {
				this.setDefaultBox(data);
			}
		}

		this.hideLoader();
	}

	/**
	 * Alapértelmezett box megjelenítés. Ha resten érkezett nem bejelentkezett állapotban URL, akkor azt használjuk a link-ben.
	 * Ha nem jön semmi / hibára futott a rest, akkor nem jelenik meg semmi.
	 */
	setDefaultBox(data) {
		let $defaultBox = $(`[${this.DOM_SELECTORS.BOX_ID_SELECTOR}="${this.DEFAULT_BOX_ID}"]`);

		if (data.offerId) {
			$defaultBox.find(this.DOM_SELECTORS.LINK)[0].href = data.offerId;
		}

		$defaultBox.show();
		this.initEventLog($defaultBox[0]);
	}

	initEventLog(container) {
		EventLog.promoButton(container, this.DOM_SELECTORS.LINK);
	}

	hideLoader() {
		$(this.DOM_SELECTORS.LOADER).hide();
	}
}
