import $ from 'jquery';
import '../../lib/jquery-svg/jquery-svg';
const BG_SIZE_ATTRIBUTE = 'data-bg-size';
const BG_SIZE_CSS_RULE = 'background-size';
const BG_POSITION_ATTRIBUTE = 'data-bg-position';
const BG_POSITION_CSS_RULE = 'background-position';

export class SVGPosition {
	constructor(container, options) {
		let $container = $(container);

		this.bgSizeElements = $container.find(`[${BG_SIZE_ATTRIBUTE}]`);
		this.bgPositionElements = $container.find(`[${BG_POSITION_ATTRIBUTE}]`);

		if (options && options.initDelay && !isNaN(options.initDelay)) {
			setTimeout(() => this.setBgSizes(), options.initDelay);
			setTimeout(() => this.setBgPositions(), options.initDelay);
		} else {
			this.setBgSizes();
			this.setBgPositions();
		}
	}

	setBgSizes() {
		this.setBgStyle(this.bgSizeElements, BG_SIZE_ATTRIBUTE, BG_SIZE_CSS_RULE);
	}

	setBgPositions() {
		this.setBgStyle(this.bgPositionElements, BG_POSITION_ATTRIBUTE, BG_POSITION_CSS_RULE);
	}

	setBgStyle(elements, attribute, cssRule) {
		elements.each((i, container) => {
			let $container = $(container);
			let $object = $container.find('object');
			let object = $object.get(0);

			if (object) {
				let styleString = `svg {
					${cssRule}: ${$container.attr(attribute)};
				}`;

				if ($(object.contentDocument).find('svg').length == 0) {
					$object.on('load', () => this.onloadHandler($object, styleString));
				} else {
					this.onloadHandler($object, styleString);
				}
			}
		});
	}

	onloadHandler($object, styleString) {
		$object.setSVGStyle(styleString);
	}
}
