import $ from 'jquery';
import PersonalizedModulesApi from '../api/PersonalizedModulesApi';
import EventLog from './EventLog';

/**
 * perszonalizált M11 modul
 */
export class ChangeCardData {
	constructor(container) {
		this.service = new PersonalizedModulesApi();
		this.LOADER_SELECTOR = '.m11p-device-card-loader';
		this.CARDS_SELECTOR_ID = '.js-card';
		this.CARD_DATA_SELECTORS = {
			HEADER: 'h3',
			BULLETPOINT_DESCRIPTION: 'ul',
			DEVICE_PRICE_BLOCK: '.device-price',
			IMAGE: 'img',
			OBJECT: 'object',
			HREF: 'a',
			IMG_WRAPPER: '.device-card-item__img-wrap',
			PERSONALIZED_OFFER_URL: 'data-m11p-personalized-url',
			PERSONALIZED_ID: 'data-personalized-id',
			MODULE_ID: 'data-m11p-module-id',
			PROMO_SINGLE_BUTTON: '.module-link-single',
			PROMO_BUTTON: '.btn-primary',
			TITLE: '.device-card__title'
		};
		this.DEVICE_PRICE_BLOCK_CLASSES = {
			description: 'device-price__description ff-reg fs-1-25',
			nettoBrutto: 'device-price__netto-brutto-text ff-reg fs-1-25',
			oldPrice: 'device-price__old-price ff-reg fs-1-25',
			mainprice: 'device-price__price ff-ult fs-2-5',
			magentaText: 'device-price__additional-text device-price__additional-text--marked ff-bold fs-1-25',
			paymentInstallmentText: 'device-price__additional-text ff-reg fs-1-25',
			fullPrice: 'device-price__additional-text ff-reg fs-1-25'
		};
		this.BULLETPOINT_DESCRIPTION_CLASS = 'ff-reg fs-1-25';
		this.MAX_BULLETPOINT_IN_UL_DESCRIPTION = 4;
		this.container = container;
		this.cardsData = null;
		this.visiblePersonalizedElementIds = [];
		this.showLoader();
		this.hideCards();
		this.getCardData();
	}

	/**
	 * @case1: successCallback, sikeres a rest és nem üres a válasz, akkor personalizalt ájánlat jelenik meg.
	 * @case2: errorCallback, sikertelen rest vagy üres adat esetén meghagyja az alapértelmezetten beszerkeztett tartalmat.
	 */
	getCardData() {
		let personalizedOfferUrl = this.getAttributeValue(this.CARD_DATA_SELECTORS.PERSONALIZED_OFFER_URL);
		let cetModuleId = this.getAttributeValue(this.CARD_DATA_SELECTORS.MODULE_ID);

		this.service.postModuleInfo(personalizedOfferUrl, cetModuleId).then(
			(response) => {
				this.initPersonalizedCards(response);
			},
			() => {
				this.showContent();
			}
		);
	}

	initPersonalizedCards(response) {
		this.cardsData = response;

		if (Array.isArray(this.cardsData) && this.cardsData.length > 0) {
			this.replaceData();
		}

		this.showContent();
	}

	showContent() {
		this.showCards();
		this.hideLoader();
		this.initEventLog();
	}

	replaceData() {
		let cards = $(this.container).find(this.CARDS_SELECTOR_ID);
		for (let i = 0; i < cards.length; i++) {
			let cardData = this.cardsData[i];
			this.visiblePersonalizedElementIds.push(this.cardsData[i]._id);

			if (!cardData) {
				return;
			}

			let $card = $(cards[i]);
			$(cards[i]).attr(this.CARD_DATA_SELECTORS.PERSONALIZED_ID, cardData._id);
			this.replaceDescription($card, cardData.productDescription);
			$card.find(this.CARD_DATA_SELECTORS.HEADER)[0].innerText = cardData.productName;
			this.devicePriceBlock($card.find(this.CARD_DATA_SELECTORS.DEVICE_PRICE_BLOCK), cardData.price);
			this.setImage($card, cardData.productPictures);
			$card.find(this.CARD_DATA_SELECTORS.HREF)[0].href = cardData.url;
		}
	}

	replaceDescription($card, productDescription) {
		let ulDescription = $card.find(this.CARD_DATA_SELECTORS.BULLETPOINT_DESCRIPTION);
		let hasDescriptionBlock = ulDescription.length;
		if (hasDescriptionBlock) {
			this.replaceLiElements(ulDescription, productDescription);
		}
	}

	replaceLiElements(ulDescription, productDescription) {
		let liHtmlElem = '<li></li>';
		let elemType = 'li';
		ulDescription.find('li').remove();
		let maxLength = productDescription.length > this.MAX_BULLETPOINT_IN_UL_DESCRIPTION ? this.MAX_BULLETPOINT_IN_UL_DESCRIPTION : productDescription.length;
		for (let i = 0; i < maxLength; i++) {
			this.elementAddClassAndSetText(ulDescription, liHtmlElem, elemType, productDescription[i], this.BULLETPOINT_DESCRIPTION_CLASS, i);
		}
	}

	devicePriceBlock(devicePriceElement, priceData) {
		let pElem = '<p></p>';
		let elemType = 'p';
		devicePriceElement.find(elemType).remove();
		let index = 0;
		for (let key in priceData) {
			if (priceData[key]) {
				this.elementAddClassAndSetText(devicePriceElement, pElem, elemType, priceData[key], this.DEVICE_PRICE_BLOCK_CLASSES[key], index);
				index++;
			}
		}
	}

	elementAddClassAndSetText(parent, appendToElement, elemType, text, addClass, index) {
		parent.append(appendToElement);
		let $htmlElement = $(parent.find(elemType)[index]);
		$htmlElement.addClass(addClass);
		$($htmlElement[0]).html(text);
	}

	/**
	 * Kiválasztja a resten érkező képekből azt amelyik a legnagyobb méretű és még belefér a kártyába.
	 * A kép szerepelhet img-tagben és object tag-ben is, de mindig img taget rak vissza a domba.
	 * @param $card: dom element
	 * @param imageData: rest adat
	 */
	setImage($card, imageData) {
		let $imgTag = $card.find(this.CARD_DATA_SELECTORS.IMAGE);
		let $objectTag = $card.find(this.CARD_DATA_SELECTORS.OBJECT);
		let $foundedImg = $imgTag.length > 0 ? $imgTag : $objectTag;

		if ($foundedImg.length === 0) {
			return;
		}

		const viewportWidth = $(window).width();
		let imgDataIndex = imageData.length - 1;

		for (let i = 0; i < imageData.length; i++) {
			if (imageData[i].width >= viewportWidth) {
				imgDataIndex = i;
				break;
			}
		}

		$($card).find(this.CARD_DATA_SELECTORS.IMG_WRAPPER).html(`<img src="${imageData[imgDataIndex].url}"alt="${imageData[imgDataIndex].alt}" title="${imageData[imgDataIndex].title}"></img>`);
	}

	getAttributeValue(attribute, container = this.container) {
		return $(container).val($(attribute)).attr(attribute);
	}

	initEventLog() {
		const cards = $(this.container).find(this.CARDS_SELECTOR_ID);

		for (let i = 0; i < cards.length; i++) {
			const promoButtonSelector = $(cards[i]).find(this.CARD_DATA_SELECTORS.PROMO_BUTTON).length > 0 ? this.CARD_DATA_SELECTORS.PROMO_BUTTON : this.CARD_DATA_SELECTORS.PROMO_SINGLE_BUTTON;
			EventLog.promoButton(cards[i], promoButtonSelector);
		}
	}

	showLoader() {
		$(this.LOADER_SELECTOR).show();
	}

	hideLoader() {
		$(this.LOADER_SELECTOR).hide();
	}

	hideCards() {
		$(this.container).hide();
	}

	showCards() {
		$(this.container).show();
	}
}
