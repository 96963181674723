import $ from 'jquery';
import { SWHREF } from './Class/SWHREF';
import { NoBubble } from './Class/NoBubble';

const INLINE_PROMO_CUSTOM_SERVICES_CONTAINER_SELECTOR = '.module-container.inline-promo-custom-services';

$(INLINE_PROMO_CUSTOM_SERVICES_CONTAINER_SELECTOR).each(function () {
	SWHREF.init(this);
	NoBubble.init(this);
});
