/* eslint-disable camelcase */
import $ from 'jquery';
const NO_SLIDER_COOKIE_NAME = 'm31-noslider';
const isElementInViewport = (el) => {
	if (typeof $ === 'function' && el instanceof $) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ && rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */;
};
const getUrlParameter = () => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.has('noslider');
};
const getStorageValue = () => {
	return sessionStorage.getItem(NO_SLIDER_COOKIE_NAME);
};
const setStorageValue = (value) => {
	sessionStorage.setItem(NO_SLIDER_COOKIE_NAME, value);
};
const noSliderUrlParam = getUrlParameter();
if (noSliderUrlParam) {
	setStorageValue(true);
}
const noSlider = getStorageValue() || noSliderUrlParam;

$(document).ready(function () {
	var mainCarousel = $('#main-slider');
	if (!noSlider) {
		const mainSlider = mainCarousel.closest('.module-container');
		mainSlider.removeClass('main-slider--hide');
		var loggedImpressionsEA = [];
		mainCarousel.on('init', function (event, slick) {
			var elSlide = $(slick.$slides[0]);

			var isInViewport = isElementInViewport($('.main-slider__nav'));

			if (window.dataLayer && isInViewport) {
				var slideNumber = parseInt($('.main-slider__nav-item.active').data('index'), 10) + 1;
				var ga_EA = $('.main-slider__nav-item.active').children('span').text() + ' slide ' + slideNumber;
				var ga_EL = elSlide.children('a').attr('href');
				if (!loggedImpressionsEA.includes(ga_EA)) {
					dataLayer.push({ event: 'newCarouselImpression', ec: 'newCarousel – impression', ea: ga_EA, el: ga_EL });
					loggedImpressionsEA.push(ga_EA);
				}
			}
		});

		var slideTime = 6000;

		mainCarousel.slick({
			arrows: false,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			autoplay: true,
			useTransform: false,
			autoplaySpeed: slideTime,
			speed: 350,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						arrows: true
					}
				}
			]
		});

		mainCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			var elSlide = $(slick.$slides[nextSlide]);
			var isMenuColorDark = elSlide.hasClass('main-slider__item--dark');
			var isMenuColorLight = elSlide.hasClass('main-slider__item--light');

			if (typeof window?.frameFunctions?.setTheme === 'function') {
				if (isMenuColorDark) {
					window.frameFunctions.setTheme('dark-transparent');
				} else if (isMenuColorLight) {
					window.frameFunctions.setTheme('light-transparent');
				}
			}

			$('.main-slider__container .main-slider__nav')
				.find("[data-index='" + currentSlide + "']")
				.removeClass('active');
			$('.main-slider__container .main-slider__nav')
				.find("[data-index='" + nextSlide + "']")
				.addClass('active');

			slideTime = mainCarousel.data('slideTime') * 1000 || 6000;
			elSlide = $(slick.$slides[nextSlide]);

			mainCarousel.slick('setOption', 'autoplaySpeed', slideTime);

			var isInViewport = isElementInViewport($('.main-slider__nav'));

			if (window.dataLayer && isInViewport) {
				var slideNumber = parseInt($('.main-slider__nav-item.active').data('index'), 10) + 1;
				var ga_EA = $('.main-slider__nav-item.active').children('span').text() + ' slide ' + slideNumber;
				var ga_EL = elSlide.children('a').attr('href');
				if (!loggedImpressionsEA.includes(ga_EA)) {
					dataLayer.push({ event: 'newCarouselImpression', ec: 'newCarousel – impression', ea: ga_EA, el: ga_EL });
					loggedImpressionsEA.push(ga_EA);
				}
			}
		});
		mainCarousel.on('swipe', function () {
			mainCarousel.slick('slickPause');
			$('.main-slider__container').addClass('autoPlayStopped');
		});
		$('.main-slider__container .main-slider__nav-item').on('click', function () {
			$('.main-slider__container .main-slider__nav-item').each(function () {
				$(this).removeClass('active');
			});
			$(this).addClass('active');
			var slider_index = $(this).data('index');
			mainCarousel.slick('slickGoTo', slider_index, false);
			mainCarousel.slick('slickPause');
			$('.main-slider__container').addClass('autoPlayStopped');
		});
		$('.main-slider__container .slick-arrow').on('click', function () {
			mainCarousel.slick('slickPause');
			$('.main-slider__container').addClass('autoPlayStopped');
		});

		$('#main-slider a').click(function () {
			if (window.dataLayer) {
				var slideNumber = parseInt($('.main-slider__nav-item.active').data('index'), 10) + 1;
				var ga_EA = $('.main-slider__nav-item.active').children('span').text() + ' slide ' + slideNumber;
				var ga_EL = $(this).attr('href');
				dataLayer.push({ event: 'newCarouselClick', ec: 'newCarousel – click', ea: ga_EA, el: ga_EL });
			}
		});
	}
});
