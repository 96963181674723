/* eslint-disable max-lines */
/* eslint-disable camelcase */
import jQuery from 'jquery';
export function carouselFunc() {
	var M28ModuleNum = jQuery("div[id^='m28-module-']").length;
	for (let i = 1; i <= M28ModuleNum; i++) {
		const m28_module_selector = '#m28-module-' + i;
		const m28_devices_carousel_selector = m28_module_selector + ' .m28_devices_carousel';
		const m28_devices_carousel_slick_track_selector = m28_module_selector + ' .m28_devices_carousel .slick-track';
		const m28_devices_carousel_inner_cont_selector = m28_module_selector + ' .m28_devices_carousel_inner_cont';
		const indicator_selector = m28_module_selector + ' .indicator';
		const scrollbar_selector = m28_module_selector + ' .scrollbar';
		const slick_slide_selector = m28_module_selector + ' .slick-slide';
		var m28_devices_carousel = jQuery(m28_devices_carousel_selector);
		if (!m28_devices_carousel.hasClass('carousel-done')) {
			m28_devices_carousel.slick({
				arrows: true,
				appendArrows: jQuery(m28_devices_carousel_inner_cont_selector),
				dots: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: false,
				speed: 200,
				responsive: [
					{
						breakpoint: 1281,
						settings: {
							slidesToShow: 3
						}
					},
					{
						breakpoint: 991,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 577,
						settings: {
							slidesToShow: 1,
							arrows: false,
							dots: true
						}
					}
				]
			});
		}
		m28_devices_carousel.addClass('carousel-done');
		m28_devices_carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			var curr_ratio;
			curr_ratio = nextSlide / slick.slideCount;
			var iW;
			iW = jQuery(indicator_selector).width();
			jQuery(scrollbar_selector).animate(
				{
					left: iW * curr_ratio
				},
				slick.options.speed - 100
			);
		});

		let optWidth = jQuery(m28_devices_carousel_inner_cont_selector).width();
		jQuery(indicator_selector).css({
			width: optWidth
		});
		if (m28_devices_carousel.slick('getSlick').slideCount) {
			jQuery(scrollbar_selector).css({
				width: (optWidth / m28_devices_carousel.slick('getSlick').slideCount) * m28_devices_carousel.get(0).slick.options.slidesToShow
			});
		}

		delete jQuery(scrollbar_selector).draggable;
		jQuery(scrollbar_selector).draggable({
			axis: 'x',
			containment: indicator_selector,
			scroll: false
		});
		delete jQuery(scrollbar_selector).on;
		jQuery(scrollbar_selector).on('dragstart', function () {
			window.scrollDragging = true;
		});
		jQuery(scrollbar_selector).on('dragstop', function () {
			window.scrollDragging = false;
			console.log('dragstop');
			let iW;
			iW = jQuery(indicator_selector).width();
			let sW;
			sW = jQuery(scrollbar_selector).width();
			let palcikaRatio;
			palcikaRatio = iW - sW;

			let palcikaPosition;
			palcikaPosition = jQuery(scrollbar_selector).position().left / palcikaRatio;
			if (palcikaPosition < 0.01) palcikaPosition = 0;
			if (palcikaPosition > 0.99) palcikaPosition = 1;

			let newPos;
			newPos = (jQuery(m28_devices_carousel_slick_track_selector).width() - iW) * palcikaPosition;

			let slider_width;
			slider_width = jQuery(slick_slide_selector).width();
			let stepCount;
			stepCount = Math.round(newPos / slider_width);
			m28_devices_carousel.slick('slickGoTo', stepCount, false);
		});
		jQuery(scrollbar_selector).on('drag', function () {
			let iW;
			iW = jQuery(indicator_selector).width();
			let sW;
			sW = jQuery(scrollbar_selector).width();
			let palcikaRatio;
			palcikaRatio = iW - sW;

			let palcikaPosition;
			palcikaPosition = jQuery(scrollbar_selector).position().left / palcikaRatio;
			if (palcikaPosition < 0.01) palcikaPosition = 0;
			if (palcikaPosition > 0.99) palcikaPosition = 1;

			let newPos;
			newPos = (jQuery(m28_devices_carousel_slick_track_selector).width() - iW) * palcikaPosition;

			jQuery(m28_devices_carousel_slick_track_selector).css('transform', 'translate3d(-' + newPos + 'px, 0px, 0px)');
		});

		jQuery(window).resize(function () {
			optWidth = jQuery(m28_devices_carousel_inner_cont_selector).width();
			jQuery(indicator_selector).css({
				width: optWidth
			});
			if (m28_devices_carousel.slick('getSlick').slideCount) {
				jQuery(scrollbar_selector).css({
					width: (optWidth / m28_devices_carousel.slick('getSlick').slideCount) * m28_devices_carousel.get(0).slick.options.slidesToShow
				});
			}
		});
	}
}
