/* eslint-disable camelcase */

import $ from 'jquery';

function goDeeplink() {
	var dlClassName = 'go-deeplink';
	var dlParamFilter = '_dl_';

	var qParams = location.search === '' ? [] : location.search.substring(1).split('&');
	var appendableQParams = '';
	for (var i = 0; i < qParams.length; i++) {
		if (qParams[i].split('=')[0].indexOf(dlParamFilter) > -1) {
			appendableQParams = appendableQParams + qParams[i] + (i === qParams.length - 1 ? '' : '&');
		}
	}

	if (appendableQParams !== '') {
		var links = document.getElementsByTagName('a');
		for (var j = 0; j < links.length; j++) {
			if (links[j].className !== '' && links[j].className.indexOf(dlClassName) > -1) {
				var url = links[j].href;
				url += url.indexOf('?') === -1 ? '?' : '&';
				url += appendableQParams;
				links[j].href = url;
			}
		}

		var links_swhref = document.getElementsByTagName('div');
		for (var k = 0; k < links_swhref.length; k++) {
			if (links_swhref[k].className !== '' && links_swhref[k].className.indexOf(dlClassName) > -1) {
				var divUrl = links_swhref[k].getAttribute('data-swhref');
				divUrl += divUrl.indexOf('?') === -1 ? '?' : '&';
				divUrl += appendableQParams;
				links_swhref[k].setAttribute('data-swhref', divUrl);
			}
		}
	}
}

$(document).ready(function () {
	goDeeplink();
});
