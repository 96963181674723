import jQuery from 'jquery';
('use strict');
(function (n) {
	n.fn.getSVG = function () {
		var t = this[0].contentDocument;
		return n(t);
	};
	n.fn.setSVGStyle = function (n) {
		var t = this[0].contentDocument,
			i = t.createElementNS('http://www.w3.org/2000/svg', 'style');
		i.textContent = n;
		t.getElementsByTagName('svg')[0].appendChild(i);
		return;
	};
	n.fn.setSVGStyleLink = function (n) {
		var i = this[0].contentDocument,
			t = i.createElementNS('http://www.w3.org/1999/xhtml', 'link');
		t.setAttribute('href', n);
		t.setAttribute('type', 'text/css');
		t.setAttribute('rel', 'stylesheet');
		i.getElementsByTagName('svg')[0].appendChild(t);
		return;
	};
	n.getRandom = function (n, t) {
		return Math.floor(Math.random() * (t - n + 1)) + n;
	};
})(jQuery);
