/* eslint-disable max-lines */
import $ from 'jquery';
import 'slick-carousel';
import angular from 'angular';
import { carouselFunc } from '../devices_carousel_functions';
import '../lib/jquery-ui/jquery-ui.min';
import PersonalizedModulesApi from './api/PersonalizedModulesApi';
import EventLog from './Class/EventLog';
import { percentInViewport } from '../common/percent-in-viewport';
const RESULT_TYPES = {
	CMS: 'cms',
	RAMA: 'rama',
	RECO: 'reco'
};
const convertDeviceToClickstream = (device, index) => ({
	position: index + 1,
	deviceVersion: device.deviceVersion,
	bundleVersion: device.bundleVersion,
	productId: device.prodId,
	skuId: device.jsku,
	productName: device.productName,
	source: RESULT_TYPES.RECO
});
$(document).ready(function () {
	var M28ModuleNum = angular.element("div[id^='m28-module-']").length;
	for (let i = 1; i <= M28ModuleNum; i++) {
		const actualControllerName = 'm28-controller-' + i;

		angular
			.module('m28-module-' + i, [])
			.run([
				'$locale',
				function ($locale) {
					$locale.NUMBER_FORMATS.CURRENCY_SYM = '';
					$locale.NUMBER_FORMATS.GROUP_SEP = ' ';
					$locale.NUMBER_FORMATS.DECIMAL_SEP = ',';
				}
			])
			.controller(actualControllerName, [
				'$scope',
				'$timeout',
				'$window',
				function ($scope, $timeout, $window) {
					$scope.devices;
					$scope.m28moduleConfig;
					$scope.personalizedModulesApi = new PersonalizedModulesApi();
					const M28Element = document.querySelector('div[data-ng-controller=' + actualControllerName + ']');
					$scope.getDevices = function () {
						$scope.m28moduleConfig = {
							cetModuleId: M28Element.dataset.cetModuleId,
							category: M28Element.dataset.category
								.replace(/ /g, '')
								.split(',')
								.filter((part) => part),
							productNames: M28Element.dataset.productNames.replace(/ /g, '').split(','),
							minOfferCount: parseInt(M28Element.dataset.minOfferCount),
							maxOfferCount: parseInt(M28Element.dataset.maxOfferCount),
							personalizedOfferUrl: M28Element.dataset.personalizedOfferUrl,
							refurbished: M28Element.dataset.refurbished
						};
						try {
							$scope.personalizedModulesApi.postModuleInfo($scope.m28moduleConfig).then((response) => {
								$scope.devices = response;
								if ($scope.devices.length) {
									$scope.$apply();
									$timeout(carouselFunc, 0);
									$timeout($scope.logClickstreamEvents, 0);
									$timeout($scope.logEventsToGA, 0);
								}
							});
						} catch (err) {
							console.error('No portal persistent cookie available');
						}
					};

					function getFullyVisibleElements(container) {
						const visibleElements = [];
						angular
							.element(container)
							.find('.slick-slide')
							.each(function (index) {
								const visible = percentInViewport(this, 50, 50, container);
								if (visible) {
									const device = convertDeviceToClickstream($scope.devices[index], index);
									visibleElements.push(device);
								}
							});
						return visibleElements;
						// const sliderWidth = container.outerWidth();
						// const visibleElements = Math.floor(sliderWidth / slick.slideWidth);
						// return Array.from({ length: visibleElements }, (_, index) => index + 1 + slick.currentSlide);
					}

					$scope.logClickstreamEvents = function () {
						const cetModuleId = $scope.m28moduleConfig.cetModuleId;
						const slider = angular.element(M28Element).find('.m28_devices_carousel');
						const title = angular.element(M28Element).find('.inner_section h2').text();
						const isGtmEnabled = !!(window.dataLayer && typeof window.dataLayer.push == 'function');
						EventLog.clickstreamBaseload(cetModuleId, {
							details: {
								// eslint-disable-next-line camelcase
								gtm_enabled: isGtmEnabled,
								text: title,
								deviceList: $scope.devices.map(convertDeviceToClickstream)
							}
						});
						const inViewport = function () {
							$scope.$apply(function () {
								if (!$scope.inViewPortLogged) {
									const innerContainer = M28Element.querySelector('.m28_devices_carousel_inner_cont');
									if (percentInViewport(innerContainer, 50, 50)) {
										const elements = getFullyVisibleElements(slider[0]);
										if (elements.length) {
											$scope.inViewPortLogged = true;
											EventLog.clickstreamInViewport(cetModuleId, {
												details: {
													text: title,
													deviceList: elements
												}
											});
										}
									}
								}
							});
						};
						inViewport();
						angular.element($window).bind('scroll', inViewport);
						slider.on('afterChange', () => {
							const elements = getFullyVisibleElements(slider[0]);
							if (elements.length) {
								EventLog.clickstreamScroll(cetModuleId, {
									details: {
										text: title,
										deviceList: elements
									}
								});
							}
						});

						angular
							.element(M28Element)
							.find('.m28_devices_carousel_item')
							.on('click', (event) => {
								const clickedIndex = angular.element(event.currentTarget).parents('[data-slick-index]').data('slick-index');
								const clickedDevice = $scope.devices[clickedIndex];

								EventLog.clickstreamClick(
									cetModuleId,
									{
										details: {
											deviceList: [
												{
													position: clickedIndex + 1,
													skuId: clickedDevice.jsku,
													productId: clickedDevice.prodId,
													deviceVersion: clickedDevice.deviceVersion,
													bundleVersion: clickedDevice.bundleVersion,
													productName: clickedDevice.productName,
													source: RESULT_TYPES.RECO
												}
											],
											text: title
										}
									},
									true
								);
							});
					};

					$scope.logEventsToGA = function () {
						if (typeof ga === 'function') {
							ga('create', 'UA-35411458-15', 'auto');
							let eventCategory = 'CET_M28';
							let eventAction;
							let eventLabel;

							let slickItems = angular.element('.m28_devices_carousel > div > div > div');
							slickItems.on('mousedown', function () {
								slickItems.on('mouseup mousemove', function handler(evt) {
									let eventCategoryIndex = angular.element(this).data('slickIndex');
									eventCategory = 'CET_M28_' + eventCategoryIndex;
									eventLabel = angular.element(this).find('.text_title').text();
									if (evt.type === 'mouseup') {
										eventAction = 'Click_Item';
									} else {
										eventAction = 'Drag_Item';
									}
									ga('send', { hitType: 'event', eventCategory: eventCategory, eventAction: eventAction, eventLabel: eventLabel });
									slickItems.off('mouseup mousemove', handler);
								});
							});
							angular.element('.scrollbar').on('dragstop', function () {
								eventAction = 'Scrollbar_Drag';
								ga('send', { hitType: 'event', eventCategory: eventCategory, eventAction: eventAction });
							});

							angular.element('.m28_devices_carousel_inner_cont button.slick-arrow').click(function (e) {
								eventAction = 'Click_Item';
								eventLabel = e.target.classList[0];
								ga('send', { hitType: 'event', eventCategory: eventCategory, eventAction: eventAction, eventLabel: eventLabel });
							});
						}
					};
					$scope.getDevices();
				}
			])
			.filter('trusted', [
				'$sce',
				function ($sce) {
					return function (html) {
						return $sce.trustAsHtml(html);
					};
				}
			]);
	}

	// több m28-as modul kezelése
	angular.element(document).ready(function () {
		for (let i = 1; i <= M28ModuleNum; i++) {
			angular.bootstrap(document.getElementById('m28-module-' + i), ['m28-module-' + i]);
		}
	});
});
