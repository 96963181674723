import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';

const TERTIARY_LINK_COLLECTION_ITEM_SELECTOR = '.tertiary-link-collection--item';
const TERTIARY_LINK_TITLE_SELECTOR = '.tertiary-link-collection--title';
const VISIBLE_ARROW_SELECTOR = '.tertiary-link-collection--arrow:visible';
const CLOSED_CLASS_NAME = 'closed';

const BOX_HEIGHTS_OPTIONS = {
	container: '.tertiary-link-collection',
	boxSelector: TERTIARY_LINK_TITLE_SELECTOR
};

function openLinkCollection(e) {
	let $title = $(e.currentTarget);
	let shouldOpen = $title.find(VISIBLE_ARROW_SELECTOR).length > 0;

	if (shouldOpen) {
		$title.parent().toggleClass(CLOSED_CLASS_NAME);
	}
}

$(TERTIARY_LINK_COLLECTION_ITEM_SELECTOR).each(function () {
	$(this).find(TERTIARY_LINK_TITLE_SELECTOR).click(openLinkCollection);
});

// eslint-disable-next-line no-new
new BoxHeights(BOX_HEIGHTS_OPTIONS);
