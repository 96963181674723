/* eslint-disable no-new */
import $ from 'jquery';
import { Carousel } from './Class/Carousel';
import { SVGPosition } from './Class/SVGPosition';
import { SWHREF } from './Class/SWHREF';
import { ButtonHoverHandler } from './Class/ButtonHoverHandler';
import { ViewportAPI } from './Class/ViewportAPI';
import { NoBubble } from './Class/NoBubble';

const IMAGE_WAIT_OUT = 8000;

const SVG_POSITION_OPTIONS = {
	initDelay: 250
};

const ANIMATION_TIMES = {
	SLIDE_DURATION: 500,
	FADE_IN: 600
};

const SELECTORS = {
	INLINE_PROMO_CONTAINER: '.module-container.main-promo',
	INLINE_PROMO_WRAP: '.main-promo-wrap',
	SELECT_SELECTOR: '.main-promo-pager-select .progressDeviceSelect',
	PAGER_SELECTOR: '.main-promo-pager__item',
	PAGER_ACTIVE_CLASS: 'main-promo-pager__item--active',
	SLIDE_SELECTOR: '.main-promo-slide',
	LOADER_SELECTOR: '.main-promo-loader',
	BUTTON_SELECTOR: '.btn-secondary, .module-link-single'
};

const MAIN_PROMO_SWIPER_PARAMS = {
	wrapperClass: 'main-promo__content',
	loop: true,
	slideClass: 'main-promo-slide',
	prevButton: '.main-promo-controls-prev',
	nextButton: '.main-promo-controls-next',
	duration: ANIMATION_TIMES.SLIDE_DURATION
};

const PARENT_CLASS = 'btn-event-trigger';
let viewportAPI = null;

class ImageLoadChecker {
	constructor(imageWaitTimeout = IMAGE_WAIT_OUT) {
		this.imageLoadPromises = [];
		this.objectLoadPromises = [];
		this.imageWaitTimeout = imageWaitTimeout;
	}

	addImageUrl(url) {
		this.imageLoadPromises.push(
			new Promise((resolve, reject) => {
				var img = $('<img />').attr('src', url);
				// ha cachelve van, akkor ne varjunk a betoltesre
				if (img.get(0).complete) {
					resolve();
				} else {
					img.on('load', resolve);
				}
				setTimeout(reject, this.imageWaitTimeout, 'Sikertelen kép letöltés: ' + url);
			})
		);
	}

	addImages(images) {
		images.each((i, image) => this.addImageUrl(image.src));
	}

	addImageUrlFromObject(object) {
		if ($(object.contentDocument).find('svg').length === 0) {
			let onloadPromise = new Promise((resolve, reject) => {
				object.onload = () => {
					this.addImageUrlFromObject(object);
					resolve();
				};

				setTimeout(reject, this.imageWaitTimeout, 'Sikertelen object betöltés');
			});

			this.objectLoadPromises.push(onloadPromise);
		} else {
			let svgBackground = $(object.contentDocument).find('svg').css('backgroundImage');
			let svgBackgroundImageURL = svgBackground.match(/\((.*?)\)/)[1].replace(/('|")/g, '');

			this.addImageUrl(svgBackgroundImageURL);
		}
	}

	addObjects(objects) {
		objects.each((i, obj) => this.addImageUrlFromObject(obj));
	}

	get load() {
		return Promise.all(this.objectLoadPromises).then(() => {
			// Objectek betöltöttek
			return Promise.all(this.imageLoadPromises);
		});
	}
}

class SVGHeight {
	constructor(container) {
		this.$container = $(container);
		this.windowWidth = $(window).width();

		this.onWidthChange(() => {
			if (this.shouldCalculate()) {
				this.startCalculate();
			} else {
				this.resetImageHeight();
			}
		});

		if (this.shouldCalculate()) {
			this.startCalculate();
		}
	}

	startCalculate() {
		let that = this;
		setTimeout(function () {
			that.$container.find('.main-promo-slide__content').each((i, svgContainer) => that.calculateImageHeight(svgContainer));
		}, 0);
	}

	shouldCalculate() {
		return this.$container.find(SELECTORS.SELECT_SELECTOR + ':visible').length === 0;
	}

	onWidthChange(cb) {
		let updateOrientation = () => {
			if (this.windowWidth != $(window).width()) {
				this.windowWidth = $(window).width();
				$(SELECTORS.INLINE_PROMO_CONTAINER).each(function () {
					// eslint-disable-next-line no-new
					new SVGPosition(this, SVG_POSITION_OPTIONS);
				});
				cb();
			}
		};

		$(window).resize(updateOrientation);

		window.addEventListener('orientationchange', updateOrientation);
	}

	calculateImageHeight(svgContainer) {
		let $svgContainer = $(svgContainer);
		let $text = $svgContainer.find('.main-promo-slide__text-wrap');
		let $svg = $svgContainer.find('.main-promo-slide__image-wrap');

		if ($text.hasClass('col-md-12') && $svg.hasClass('col-md-12')) {
			let containerHeight = $svgContainer.outerHeight();
			let textHeight = $text.outerHeight();
			let svgHeight = containerHeight - textHeight;

			$svg.css('height', svgHeight);
		}
	}

	resetImageHeight() {
		this.$container.find('.main-promo-slide__content').each((i, svgContainer) => {
			let $svgContainer = $(svgContainer);
			let $text = $svgContainer.find('.main-promo-slide__text-wrap');
			let $svg = $svgContainer.find('.main-promo-slide__image-wrap');

			if ($text.hasClass('col-md-12') && $svg.hasClass('col-md-12')) {
				$svg.removeAttr('style');
			}
		});
	}
}

class MainPromo extends Carousel {
	constructor(container, swiperParams, selectors) {
		super(container, swiperParams, selectors);

		/**
		 * Ha csak egy slide-os a promó, akkor a super() nem hozza létre a swiper objektumot,
		 * így ha nincs swiperünk nem csinálunk semmit.
		 */
		if (!this.swiper) {
			return;
		}

		this.automaticSlideEnabled = true;
		this.currentTimeoutId = null;
		this.remainingTime = 0;

		this.$container.hover(
			() => this.pauseAutomaticSlide(),
			() => this.resumeAutomaticSlide()
		);
		this.$container.on('click', `${swiperParams.prevButton}, ${swiperParams.nextButton}, ${selectors.PAGER_SELECTOR}`, () => this.stopAutomaticSlide());
		this.swiper.on('onSlideChangeEnd', () => this.setSlideHashtag());

		/**
		 * Ez itt arra jó, hogy ha valamilyen eszközön a nyitó promó select-jén állítanánk, akkor
		 * default belezoom-ol az oldalba egy picit, de kiválasztás után vissza már nem. Ezzel meg
		 * megmondjuk hogy kiválasztás után nem lehet nagyítani semmit, aztán 100 ms után meggondoljuk magunkat.
		 */
		viewportAPI.setProperty('user-scalable', 'no');
		this.$select.on('change blur', function () {
			viewportAPI.setProperty('maximum-scale', '1');

			setTimeout(function () {
				viewportAPI.setProperty('maximum-scale', '');
			}, 100);
		});

		if (this.withTimer) {
			this.automaticSlide(this.currentTimeoutTimeFromSlide);
			this.automaticSlideStartedTime = Date.now();
		}

		if (window.location.hash && window.location.hash.length) {
			let hash = window.location.hash.replace('#', '');
			this.jumpToSlideByHash(hash);
		}

		let self = this;
		$('a').click(function () {
			if ($(this).href && !$(this).href.endsWith('#')) {
				self.stopAutomaticSlide();
			}
		});
	}

	get currentTimeoutTimeFromSlide() {
		let slide = this.swiper.slides[this.swiper.activeIndex];
		let time = slide.getAttribute('data-timeout');
		let timeInMS = null;

		if (time && time.length) {
			let timeInt = parseInt(time, 10);
			if (!isNaN(timeInt)) {
				timeInMS = timeInt * 1000;
			}
		}

		return timeInMS;
	}

	get currentSlideHash() {
		return this.swiper.slides[this.swiper.activeIndex].getAttribute('data-hash') || '';
	}

	get withTimer() {
		let timeouts = true;

		this.swiper.slides.each(function (i, slide) {
			timeouts = slide.getAttribute('data-timeout') != null;
		});

		return timeouts;
	}

	jumpToSlideByHash(hash) {
		if (hash && hash.length) {
			this.pauseAutomaticSlide();
			for (let i = 0; i < this.swiper.slides.length; i++) {
				let slide = this.swiper.slides[i];
				if (slide.getAttribute('data-hash') === hash) {
					this.swiper.slideTo(i);
					break;
				}
			}
		}
	}

	setSlideHashtag() {
		if (this.currentSlideHash.length && this.automaticSlideEnabled) {
			window.location.hash = this.currentSlideHash;
		}
	}

	automaticSlide(timeout) {
		this.currentTimeoutId = setTimeout(() => {
			if (this.automaticSlideEnabled) {
				this.swiper.slideNext();
				this.automaticSlide(this.currentTimeoutTimeFromSlide);
				this.automaticSlideStartedTime = Date.now();
			}
		}, timeout);
	}

	pauseAutomaticSlide() {
		if (this.automaticSlideEnabled) {
			clearTimeout(this.currentTimeoutId);

			let now = Date.now();
			let diff = now - this.automaticSlideStartedTime;
			this.remainingTime = this.currentTimeoutTimeFromSlide - diff;
		}
	}

	resumeAutomaticSlide() {
		if (this.automaticSlideEnabled) {
			this.automaticSlide(this.remainingTime);
			this.remainingTime = 0;
		}
	}

	stopAutomaticSlide() {
		this.automaticSlideEnabled = false;
	}
}

let FIRST_SLIDES_SELECTOR = SELECTORS.SLIDE_SELECTOR + ':first-child' + ', ' + SELECTORS.SLIDE_SELECTOR + ':last-child' + ', ' + SELECTORS.SLIDE_SELECTOR + ':eq(1)';

$(document).ready(function () {
	viewportAPI = new ViewportAPI();
	$(SELECTORS.INLINE_PROMO_CONTAINER).each(function () {
		let $this = $(this);

		let imageLoadChecker = new ImageLoadChecker();

		let $firstSlides = $this.find(FIRST_SLIDES_SELECTOR);

		imageLoadChecker.addObjects($firstSlides.find('object'));
		imageLoadChecker.addImages($firstSlides.find('img'));

		let delayInSec = parseInt($this.attr('data-loader-delay'), 10);
		let delay = !isNaN(delayInSec) ? delayInSec * 1000 : null;

		setTimeout(() => {
			$this.find(SELECTORS.LOADER_SELECTOR).find('.main-promo-loader-wrap').show();
		}, delay || 3000);

		let initPromo = () => {
			new MainPromo(this, MAIN_PROMO_SWIPER_PARAMS, SELECTORS);
			new SVGPosition(this);
			new SVGHeight(this);
			SWHREF.init(this);
			NoBubble.init(this);
			$this.find(SELECTORS.LOADER_SELECTOR).remove();
		};

		imageLoadChecker.load.then(initPromo, (err) => {
			console.log(err);
			initPromo();
		});
	});
	ButtonHoverHandler.init(SELECTORS.BUTTON_SELECTOR, PARENT_CLASS);
});
