import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';
import { SWHREF } from './Class/SWHREF';
import { NoBubble } from './Class/NoBubble';

const HORIZONTAL_REDIRECTOR_CONTAINER_SELECTOR = '.module-container.horizontal-redirector';

$(HORIZONTAL_REDIRECTOR_CONTAINER_SELECTOR).each(function () {
	// eslint-disable-next-line no-new
	new SVGPosition(this);
	SWHREF.init(this);
	NoBubble.init(this);
});
