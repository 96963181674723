import $ from 'jquery';
import Swiper from 'swiper';

export class Carousel {
	constructor(container, swiperParams, selectors) {
		this.selectors = selectors;
		this.$container = $(container);

		if (this.$container.find(`.${swiperParams.slideClass}`).length > 1) {
			this.$select = this.$container.find(this.selectors.SELECT_SELECTOR);

			this.swiper = new Swiper(this.$container.find(this.selectors.INLINE_PROMO_WRAP), swiperParams);

			this.$container.find(this.selectors.PAGER_SELECTOR).click((e) => {
				e.preventDefault();
				this.handlePagerChange(e.currentTarget);
			});

			this.swiper.on('onSlideChangeEnd', () => this.handleSlideChange());
			this.$select.change(() => this.handleSelectChange());
		}
	}

	handleSlideChange() {
		let index = (this.swiper.activeIndex - 1) % (this.swiper.slides.length - 2);

		// Ha jobbról balra, az utolsóról az első diára megyünk, -1-et kaptunk indexnek, ez itt azért van.
		// a -3 meg a 2 klón, +1 nem 0-tól indexelés
		if (index < 0) {
			index = this.swiper.slides.length - 3;
		}

		this.$select.val(index);
		this.setActivePager(index);
	}

	handleSelectChange() {
		let index = parseInt(this.$select.val(), 10);
		if (!isNaN(index)) {
			// nem 0-tól számol, azé' a +1
			this.swiper.slideTo(index + 1);
		}
	}

	handlePagerChange(elem) {
		let $elem = $(elem);
		let index = $elem.index();
		// nem 0-tól számol, azé' a +1
		this.swiper.slideTo(index + 1);
		this.setActivePager(index);
	}

	setActivePager(index) {
		this.resetAllPager();
		$(this.$container.find(this.selectors.PAGER_SELECTOR)[index]).addClass(this.selectors.PAGER_ACTIVE_CLASS);
	}

	resetAllPager() {
		this.$container.find(this.selectors.PAGER_SELECTOR).removeClass(this.selectors.PAGER_ACTIVE_CLASS);
	}
}
