import $ from 'jquery';

/**
 * Ez egy eseménynek a neve, amit jquery-vel elsütve az adott elem-en újra számolgatja a magasságokat.
 * Mivel a tabfüles dobozba be lehet szúrni más modulokat is, ezért problémát okozott az hogy
 * tabváltáskor nem számolta újra adott modulnál a magasságot és összecsúsztak kicsit a dolgok.
 * Ezért ezt az eseményt pl.: a tabjáltó js-e hívja meg váltáskor.
 * @type {string}
 */
const REFRESH_EVENT_NAME = 'BoxHeights:refresh';

export class BoxHeights {
	constructor(options) {
		this.$container = $(options.container);
		if (options.container) {
			this.$container = $(options.container);
			this.$container.on(REFRESH_EVENT_NAME, () => this.refresh());
		} else {
			throw new Error('Missing "container" property on BoxHeights', options);
		}

		this.initDelay = options.initDelay || 100;
		this.boxSelector = options.boxSelector || null;

		if (this.boxSelector != null) {
			setTimeout(() => this.refresh(), this.initDelay);
			this.onWidthChange(() => this.refresh());
		} else {
			throw new Error('Missing "boxSelector" property on BoxHeights', options);
		}

		this.windowWidth = $(window).width();
	}

	refresh() {
		let maxHeight = this.getBoxMaxHeight();
		this.setBoxesHeight(maxHeight);
	}

	getBoxMaxHeight() {
		let retValue = 0;

		this.$container.find(this.boxSelector).each(function () {
			let $this = $(this);
			$this.css('height', '');
			let height = $this.outerHeight();

			if (height > retValue) {
				retValue = height;
			}
		});

		return retValue;
	}

	setBoxesHeight(height) {
		this.$container.find(this.boxSelector).each(function () {
			$(this).css('height', height === 0 ? 'auto' : height);
		});
	}

	onWidthChange(cb) {
		$(window).resize(() => {
			if (this.windowWidth != $(window).width()) {
				this.windowWidth = $(window).width();
				cb();
			}
		});
	}
}
