/* eslint max-lines: ["error", 200] */
import $ from 'jquery';
import Utils from '../../../../common/utils';

class EventLog {
	constructor() {}

	static async clickstreamBaseload(moduleId, eventData) {
		if (window.clickstream && typeof window.clickstream.logBaseLoad === 'function') {
			await window.clickstream.logBaseLoad(moduleId, eventData);
		}
	}
	static async clickstreamInViewport(moduleId, eventData) {
		if (window.clickstream && typeof window.clickstream.logInViewport === 'function') {
			await window.clickstream.logInViewport(moduleId, eventData);
		}
	}

	static async clickstreamScroll(moduleId, eventData) {
		if (window.clickstream && typeof window.clickstream.logScroll === 'function') {
			await window.clickstream.logScroll(moduleId, eventData);
		}
	}
	static async clickstreamClick(moduleId, eventData, logToStore = false) {
		if (window.clickstream && typeof window.clickstream.logUserSelect === 'function') {
			await window.clickstream.logUserSelect(moduleId, eventData, logToStore);
		}
	}
	static async clickstreamQuickSearch(devices, details) {
		if (window.clickstream && typeof window.clickstream.logQuickSearchEvent === 'function') {
			await window.clickstream.logQuickSearchEvent(devices, details);
		}
	}
	static async clickstreamBaseloadSearch(devices) {
		if (window.clickstream && typeof window.clickstream.logQuickSearchBaseloadEvent === 'function') {
			await window.clickstream.logQuickSearchBaseloadEvent(devices);
		}
	}
	static async clickstreamDetailedSearch(devices, details) {
		if (window.clickstream && typeof window.clickstream.logDetailedSearchEvent === 'function') {
			await window.clickstream.logDetailedSearchEvent(devices, details);
		}
	}
	static async clickstreamQuickSearchClick(device, details) {
		if (window.clickstream && typeof window.clickstream.logQuickSearchClickEvent === 'function') {
			await window.clickstream.logQuickSearchClickEvent(device, details, true);
		}
	}
	static async clickstreamDetailedSearchClick(device, details) {
		if (window.clickstream && typeof window.clickstream.logDetailedSearchClickEvent === 'function') {
			await window.clickstream.logDetailedSearchClickEvent(device, details, true);
		}
	}

	/**
	 * Egy promo kiválasztása esetén küldöt logok. Slider esetén küldeni kell a láthathó slide-okat is.
	 */
	static promoButton(container, promobuttonSelector) {
		$(container).on('click', promobuttonSelector, function (event) {
			event.preventDefault();

			const SELF_TARGET = '_self';
			let $link = this;
			let target = $link.target;

			if (target === SELF_TARGET) {
				window.location.href = $link.href;
			} else {
				window.open($link.href, target);
			}
		});
	}

	/**
	 * Egy html elemen található attribútum értékét adja vissza.
	 */
	static _getAttributeValue(attribute, container) {
		return $(container).val($(attribute)).attr(attribute) || '';
	}

	/**
	 * A sliderben a viewportban megtalálható slide-ok perszonalizált id-kat adja vissza.
	 * @param (dom fragment) container: cet modul container
	 * @param (class) slidesSelector: slide-ok selectora, ami minden slideon megtalálható
	 * @param (attribute) attributeSelector: slide-on található personalized attribútum selector
	 * @returns {string}
	 * @private
	 */
	static _getVisibleElementsIdInSlider(container, slidesSelector, attributeSelector) {
		let slides = $(container).find(slidesSelector);
		let visibleSlidesIds = [];

		$.each(slides, (index, element) => {
			if (Utils.isElementInViewport(element)) {
				visibleSlidesIds.push(EventLog._getAttributeValue(attributeSelector, element));
			}
		});

		return visibleSlidesIds.toString();
	}

	static getPersonalizedElementsId(containerSelector, attributeSelector) {
		let container = $(containerSelector);
		let personalizedElementsId = [];

		$.each(container, (index, element) => {
			personalizedElementsId.push(EventLog._getAttributeValue(attributeSelector, element));
		});

		return personalizedElementsId.toString();
	}
}

export default EventLog;
