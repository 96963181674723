import $ from 'jquery';

const SWHREF_ATTRIBUTE_SELECTOR = 'swhref';
const DATA_SWHREF_ATTRIBUTE_SELECTOR = '[data-swhref]';
const SWHREF_TARGET_SELECTOR = 'swtarget';

/**
 * Egy 'a' tag működést valósít meg. Arra jó, hogy ha egy kártyában van egy link,
 * akkor a kártyára rakhatunk egy data-swhref attribútumot, ami tartalmazza az 'a' tag linkjét,
 * így az egész kártyára kattintás olyan, mintha a linkre kattintana a user.
 */
export class SWHREF {
	static init(elem) {
		$(elem)
			.find(DATA_SWHREF_ATTRIBUTE_SELECTOR)
			.click(function (event) {
				if (event.target.tagName === 'A') {
					return;
				}

				const SELF_TARGET = '_self';

				let $link = $(this);
				let target = $link.data(SWHREF_TARGET_SELECTOR) || SELF_TARGET;
				let url = $link.data(SWHREF_ATTRIBUTE_SELECTOR);

				if (target === SELF_TARGET) {
					window.location.href = url;
				} else {
					window.open(url, target);
				}
			});
	}

	/**
	 * Felparaméterezett data-swhref helyett a megadott link kattintást triggerelei.
	 * Akkor hazsnos, ha elnavigálás előtt logolni kell és így elég csak a linkre tenni logolást.
	 */
	static initLinkEventTrigger(container, linkSelector = 'a') {
		let swhrefElement = $(container).find(DATA_SWHREF_ATTRIBUTE_SELECTOR);

		if (swhrefElement.length === 0) {
			swhrefElement = container;
		}

		$(swhrefElement).click(function (event) {
			if (event.target.tagName === 'A') {
				return;
			}

			$(this).find(linkSelector).trigger('click');
		});
	}
}
