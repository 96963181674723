import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';
import { SWHREF } from './Class/SWHREF';
import HorizontalRedirector from './Class/HorizontalRedirector';

const HORIZONTAL_REDIRECTOR_CONTAINER_SELECTOR = '.module-container.m17p-horizontal-redirector';
const HORIZONTAL_REDIRECTOR_LINK_SELECTOR = '.btn-brd-magenta';

$(document).ready(function () {
	if ($(HORIZONTAL_REDIRECTOR_CONTAINER_SELECTOR).length) {
		// eslint-disable-next-line no-new
		new HorizontalRedirector();
	}

	$(HORIZONTAL_REDIRECTOR_CONTAINER_SELECTOR).each(function () {
		// eslint-disable-next-line no-new
		new SVGPosition(this);
		SWHREF.initLinkEventTrigger(this, HORIZONTAL_REDIRECTOR_LINK_SELECTOR);
	});
});
