const COOKIE_EXPIRATION_IN_DAY = 365;

const COOKIE_DEFAULT_PATH = '/';

/**
 * Bejelentkezett állapotot tartalmazó cookie id, string "true" / "false" értékkészlettel
 */
const _TF_LOGGEDIN_COOKIE_NAME = 'tf_loggedIn';

/**
 * Bejelentkezett állapotot tartalmazó cookie id
 */
const _TF_ID_COOKIE_NAME = 'tf_id';

/*
 * MT portal oldali user azonosításra használt (perzisztens) cookie id
 */
const _PORTAL_PERSISTENT_COOKIE_NAME = 'PORTAL_PERSISTENT_COOKIE';

const _CONTEXT_ID_STORAGE_KEY = 'osContextId';

/**
 * Telekomban gyakran használt függvények / konstansok gyűjtőhelye.
 */
class Utils {
	constructor() {}
	static renewId() {
		const data = localStorage.getItem(_CONTEXT_ID_STORAGE_KEY);
		if (data) {
			sessionStorage.setItem(_CONTEXT_ID_STORAGE_KEY, data);
		}
	}

	static getCookie(name) {
		let regExp = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
		return document.cookie.replace(regExp, '$1');
	}

	static setCookie(name, value, expirationInDay = COOKIE_EXPIRATION_IN_DAY) {
		let expirationInMs = new Date(new Date() * 1 + expirationInDay * 864e5);
		document.cookie = `${name}=${value}; expires=${expirationInMs}`;
	}

	static setSessionCookie(name, value, path = COOKIE_DEFAULT_PATH) {
		document.cookie = `${name}=${value}; path=${path}`;
	}

	static getStorageItem(key) {
		const data = localStorage.getItem(key);
		if (data) {
			return JSON.parse(data);
		}
	}

	static setStorageItem(key, data) {
		if (data !== undefined) {
			localStorage.setItem(key, JSON.stringify(data));
		} else {
			localStorage.removeItem(key);
		}
	}

	/*
	 * Elsődlegesen a BFF hívásokhoz szükséges headerben, másodsoron a OneShop fő azonosítója, ott tároljuk, ahol az OS is
	 */
	static getContextId(uuidGenerator) {
		let storedContextId = Utils.getStorageItem(_CONTEXT_ID_STORAGE_KEY);

		if (storedContextId) {
			return storedContextId;
		}

		let contextId = (uuidGenerator || Utils.generateUuid)();

		Utils.setStorageItem(_CONTEXT_ID_STORAGE_KEY, contextId);

		return contextId;
	}

	/**
	 * Visszaadja booleanként, hogy a felhasználó bejelentkezett állapotban van-e.
	 * @returns {boolean}
	 */
	static isLoggedIn() {
		return Utils.getCookie(Utils.TF_LOGGEDIN_COOKIE_NAME) == 'true';
	}

	/**
	 * megvizsgálja, hogy a HTML elem a viewportban van-e.
	 * @param (dom fragment) element
	 * @returns {boolean}
	 */
	static isElementInViewport(element) {
		if (typeof jQuery === 'function' && element instanceof jQuery) {
			element = element[0];
		}

		let rect = element.getBoundingClientRect();

		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
	}

	static generateUuid() {
		const crypto = window.crypto || window.msCrypto;

		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			// eslint-disable-next-line no-bitwise
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	}

	/**
	 * Konstans getterek
	 */
	static get TF_LOGGEDIN_COOKIE_NAME() {
		return _TF_LOGGEDIN_COOKIE_NAME;
	}

	static get PORTAL_PERSISTENT_COOKIE_NAME() {
		return _PORTAL_PERSISTENT_COOKIE_NAME;
	}

	static get TF_ID_COOKIE_NAME() {
		return _TF_ID_COOKIE_NAME;
	}
}
Utils.renewId();
export default Utils;
