import Utils from '../../../common/utils';

const DOM_SELECTORS = {
	APP_POPUP: 'js-telekom-app-popup',
	EXIT: 'js-telekom-app-popup__close',
	OK_BUTTON: 'js-telekom-app-popup__redirect'
};
const POPUP_STATUS_COOKIE_NAME = 'showTelekomAppPopup';
const POPUP_STATUS_COOKIE_VALUE = 'noShow';

/**
 * Egy popup, ami felajánlja a telekom app megnyitását
 */
class TelekomAppPopup {
	constructor() {
		this.init();
		this.isIphone = navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null;
		this.isAndroid = navigator.userAgent.match(/android/i) != null;
		this.isMobileView = this.isIphone || this.isAndroid;
	}

	redirectToTelekomApp() {
		if (this.isIphone) {
			window.location = 'https://itunes.apple.com/hu/app/telekom-alkalmazas/id588993858?mt=8';
		} else if (this.isAndroid) {
			window.location = 'https://play.google.com/store/apps/details?id=hu.telekom.telekomapp&hl=hu';
		}
	}

	addEventHandlers() {
		document.getElementById(DOM_SELECTORS.OK_BUTTON).addEventListener('click', () => {
			Utils.setCookie(POPUP_STATUS_COOKIE_NAME, POPUP_STATUS_COOKIE_VALUE);
			this.logEvent();
			this.redirectToTelekomApp();
			this.closeLayer();
		});

		document.getElementById(DOM_SELECTORS.EXIT).addEventListener('click', () => {
			Utils.setCookie(POPUP_STATUS_COOKIE_NAME, POPUP_STATUS_COOKIE_VALUE);
			this.closeLayer();
		});
	}

	closeLayer() {
		document.getElementById(DOM_SELECTORS.APP_POPUP).style.display = 'none';
	}

	logEvent() {
		if (this.isAndroid) {
			window.dataLayer && window.dataLayer.push({ event: ' AppPromo201809', action: 'Google Play Store Click' });
		} else {
			window.dataLayer && window.dataLayer.push({ event: ' AppPromo201809', action: 'iOS App Store Click' });
		}
	}

	init() {
		const initAfterDomLoaded = () => {
			let popupHasNeverClosed = Utils.getCookie(POPUP_STATUS_COOKIE_NAME) != POPUP_STATUS_COOKIE_VALUE;

			if (this.isMobileView && window.telekomAppPopupIsEnabled && popupHasNeverClosed) {
				document.getElementById(DOM_SELECTORS.APP_POPUP).style.display = '';
				this.addEventHandlers();
			}
		};

		window.onload = initAfterDomLoaded;
	}
}

export default new TelekomAppPopup();
