import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';
import { SVGPosition } from './Class/SVGPosition';
import { ChangeCardData } from './Class/ChangeCardData';
import { SWHREF } from './Class/SWHREF';

const DEVICE_CARD_CONTAINER_SELECTOR = '.module-container.m11p-device-card';
const TITLE_SELECTOR = '.device-card-item__title';

const SVG_POSITION_OPTIONS = {
	initDelay: 1000
};

const CARD_CONTAINER_SELECTOR_ID = '[class*=js-card-container-]';

$(document).ready(function () {
	$(DEVICE_CARD_CONTAINER_SELECTOR).each(function () {
		new BoxHeights({ container: this, boxSelector: TITLE_SELECTOR }); // eslint-disable-line no-new
		new SVGPosition(this, SVG_POSITION_OPTIONS); // eslint-disable-line no-new
	});

	$(CARD_CONTAINER_SELECTOR_ID).each(function () {
		SWHREF.initLinkEventTrigger(this);
		new ChangeCardData(this); // eslint-disable-line no-new
	});
});
