import $ from 'jquery';

const NOBUBBLE_SELECTOR = '.js-nobubble';

export class NoBubble {
	static init(elem) {
		$(elem)
			.find(NOBUBBLE_SELECTOR)
			.click((e) => {
				e.stopImmediatePropagation();
			});
	}
}
