import $ from 'jquery';
import Swiper from 'swiper';

export class Slider {
	constructor(container, sliderParams, selectors) {
		this.$container = $(container);
		this.selectors = selectors;
		this.slides = this.$container.find('.' + sliderParams.slideClass);

		this.setPagerArrows(this.slides);
		sliderParams.scrollbar = this.$container.find(sliderParams.scrollbar).get(0);

		this.slider = new Swiper(this.$container.find(this.selectors.SLIDER_WRAPPER_SELECTOR).get(0), sliderParams);

		this.attachEventListeners();
		this.setPagerFunction();
	}

	itemsInOneView() {
		let slidesWidthSum = 0;

		for (let i = 0; i < this.slides.length; i++) {
			slidesWidthSum += $(this.slides[i]).outerWidth(true);
		}

		return slidesWidthSum <= this.$container.width;
	}

	handleResize() {
		this.setPagerArrows();
	}

	setPagerArrows() {
		if (this.itemsInOneView()) {
			this.$container.removeClass(this.selectors.ENABLED_CLASSNAME);
		} else {
			this.$container.addClass(this.selectors.ENABLED_CLASSNAME);
		}
	}

	setPagerFunction() {
		this.$container.find(this.selectors.NEXT_BUTTON_SELECTOR).click((e) => {
			e.preventDefault();
			if (this.slider.isEnd) {
				this.slider.slideTo(0);
			} else {
				this.slider.slideNext();
			}
		});
		this.$container.find(this.selectors.PREV_BUTTON_SELECTOR).click((e) => {
			e.preventDefault();
			this.slider.slidePrev();
		});
	}

	attachEventListeners() {
		$(window).on('resize', () => this.handleResize());
		$(document).on('TabbedContainer:tabChanged', (e, tabContent) => {
			let ownContainerVisibilityChanged = !!this.$container.closest(tabContent).length;
			if (ownContainerVisibilityChanged) {
				this.slider.update();
			}
		});
	}
}
